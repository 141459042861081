<template>
  <div class="page_zctype" style="position: relative;height: 100%;overflow: hidden">
    <div class="flexStart">
      <span>{{ name }}</span>
      <el-button type="primary" plain size="mini" @click="add()" style="margin-left: 40px;">添加大分类</el-button>

      <div class="" style="margin-left: 40px;">
        <el-button icon="el-icon-download" type="text" style="margin-left: 20px;">
          <a href="./downloadFiles/实训耗材分类导入模板.xls" target="_blank"
             style="text-decoration: none; color: inherit;font-size: 14px;">下载模板</a>
        </el-button>
        <el-link type="primary" @click="uploadXls" style="margin-left: 20px;" target="_blank">导入耗材分类</el-link>
        <div style="display: none;">
          <el-upload action="" :multiple="true" :http-request="uploadOss" :on-success="uploadAttch"
                     :show-file-list="false"
                     name="image">

            <el-button size="mini" id="addXlsbtn">+</el-button>
          </el-upload>
        </div>
      </div>
    </div>
    <div style="width: 45%; height: 100%; display: inline-block;vertical-align: top;">


      <el-card style="margin-top: 10px;height:calc( 100% - 40px );overflow-y: scroll">
        <div class="linetree">
          <el-tree class="tree" :highlight-current="true" :data="treeData" :props="defaultProps"
                   :default-expand-all="true"
                   :expand-on-click-node="false" style="width: 100%;">

            <div class="custom-tree-node flexBetween" slot-scope="{ node, data }">
              <div class="flex_1" @click="edit(node)">
                <el-tooltip :enterable="false" :disabled="node.label.length<20" class="item" effect="light" placement="top-start">
                  <div slot="content" style="width: 350px;">{{ node.label }}</div>
                  <div style="width: calc( 100% - 0px );" class="overOneLine">
                    {{ node.label }}
                  </div>
                </el-tooltip>
</div>

              <div class="cus-tree-node_btn" style="margin-left: 20px;position: relative">
                <div class="" style="position: absolute;z-index: 1;">
                  <i v-if="!node.data.is_used || (node.data.children && node.data.children.length>0)" class="el-icon-circle-plus-outline" style="color: #409eff; margin-right: 10px;"
                     @click="add(node)" title="添加下级分类"></i>
                  <i v-if="!node.data.is_used && (!node.data.children || node.data.children.length==0)" class="el-icon-edit" style="color: #67c23a; margin-right: 10px;" title="编辑"
                     @click="edit(node)"></i>
                  <i v-if="!node.data.is_used && (!node.data.children || node.data.children.length==0)" class="el-icon-delete" style="color: #fe6c6c; margin-right: 0px;" title="删除"
                     @click="del(node)"></i>
                </div>
                <div class="flexStart">
                  <i class="el-icon-circle-plus-outline" style="color: transparent; margin-right: 10px;"></i>
                  <i class="el-icon-edit" style="color: transparent; margin-right: 10px;"></i>
                  <i class="el-icon-delete" style="color: transparent; margin-right: 0px;"></i>
                </div>

              </div>

            </div>

          </el-tree>
        </div>
      </el-card>

    </div>

    <div v-show="dialogVisible" style=" width: 45%;height: 100%;position: absolute;top: 52px;right: 0;">

      <el-card>
        <div>
          <el-form ref="form" :model="EditItem" label-width="80px">


            <el-form-item label="上级分类:">
              <el-cascader ref="myCascader" v-model="EditItem.parent_code" :options="treeData1"
                           :disabled="EditItem.parent_code==0"
                           :props="defaultProps1" :show-all-levels="true" filterable clearable :placeholder="EditItem.parent_code==0?'-':'请选择'"
                           style="width: 100%;"
                           @change="handleCascaderChange"></el-cascader>
            </el-form-item>
            <el-form-item label="分类名称:">
              <el-input v-model="EditItem.cname" placeholder="分类中文名称"></el-input>

            </el-form-item>
            <el-form-item label="*英文编码:">
              <el-input v-model="EditItem.ename" placeholder="拼音首字母缩写,不要太长"></el-input>
            </el-form-item>

            <el-form-item label="计量单位:">
              <el-input v-model="EditItem.unit" placeholder="请输入计量单位"></el-input>
            </el-form-item>
            <el-divider style="width: 100%"></el-divider>
            <div style="color: #409EFF;margin: 10px 0 10px 0;">分类属性</div>

            <el-row v-for="(c,idx) in cus_list" :key="idx" class="flexStart drawer_box">
              <el-col :span="10">
                <el-form-item label="字段名称:">
                  <el-input size="small" v-model="c.name" placeholder="字段名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" style="margin-left: 10px;">
                <el-form-item label="字段类型:">
                  <el-select size="small" v-model="c.type" placeholder="请选择">
                    <el-option value="string" label="文本"></el-option>
                    <el-option value="number" label="数值"></el-option>
                    <el-option value="date" label="日期"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-tag @click="delCus(c,idx)" type="danger" size="mini" style="margin-left: 20px;"><i
                  class="el-icon-remove"></i></el-tag>
            </el-row>
            <el-row style="margin: 20px 0">
              <el-button @click="addCus" title="新增系定义属性" size="mini" type="primary" plain icon="el-icon-plus"
                         circle></el-button>
            </el-row>
          </el-form>
          <el-divider style="width: 100%;"></el-divider>
          <el-row style="margin-top: 20px">
            <el-col :span="24" style="text-align: right;">
              <el-button type="primary" plain @click="dialogVisible=false">取消</el-button>
              <el-button type="primary" @click="save">保存</el-button>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </div>

    <el-dialog title="错误日志" custom-class="cus_dialog" :visible.sync="dialogErrorVisible" :close-on-click-modal="false">
      <div style="padding-top: 10px;" v-for="item in ErrLog">{{ item }}</div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      treeData: [],
      treeData1: [],
      defaultProps: {
        children: 'children',
        label: 'cname'
      },
      defaultProps1: {
        children: 'children',
        label: 'cname',
        value:'id',
        emitPath: false, checkStrictly: true
      },
      dialogVisible: false,
      EditItem: {
        id: 0,
        ename: "",
        cname: "",
        value: "0",
        remark: "",
        unit: "",
        parent_code: 0
      },
      cus_list: [],
      dialogErrorVisible:false,
      ErrLog: [],
    }
  },
  props: ['name', 'type', 'parentPage'],
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let _this = this
      this.$http.post("/api/zc_type", {type: this.type}).then(res => {
        _this.deleteEmptyGroup(res.data).then(result => {
          _this.treeData = result
          _this.treeData1 = result
        })
      })
    },
    // 处理没有children的分组
    deleteEmptyGroup(treeData) {
      return new Promise(resolve => {
        function traversal(data) {
          data.map(item => {
            for (let info in item) {
              if (item['children']) {
                if (item['children'].length > 0) {
                  traversal(item['children'])
                }
                if (item['children'].length == 0) {
                  delete item['children']
                }
              }
            }
          })
        }

        traversal(treeData)
        resolve(treeData)
      })
    },
    add(node) {
      if (node) {
        this.EditItem = {
          id: 0,
          ename: "",
          cname: "",
          value: node.data.cname,
          remark: "",
          unit: "",
          parent_code: node.data.id
        }
      } else {
        this.EditItem = {
          id: 0,
          ename: "",
          cname: "",
          value: "0",
          remark: "",
          unit: "",
          parent_code: 0

        }
      }
      this.cus_list = []
      this.dialogVisible = true
      console.log(this.EditItem)
    },
    edit(node) {
      console.log(node)

      this.EditItem = {
        id: node.data.id,
        ename: node.data.ename,
        cname: node.data.cname,
        value: node.data.value,
        remark: node.data.remark,
        unit: node.data.unit,
        type: node.data.type,
        parent_code: node.data.parent_code

      }
      console.log(206,this.filterTableMater('id',node.data.id,this.treeData1))

      if (node.data.zdjson && node.data.zdjson != '') {
        this.cus_list = JSON.parse(node.data.zdjson)
      } else {
        this.cus_list = []

      }
      this.dialogVisible = true
    },
    /**
     * @description: 递归遍历查找数组对象的某个值
     * @param {string} code
     * @param {array} arr
     * @returns {object}
     */
    filterTableMater(label,value, arr) {
      for (const item of arr) {
        item.disabled = false
        if (item[label] === value) item.disabled = true
        if (item.children && item.children.length) this.filterTableMater(label,value, item.children)
      }
      return arr
    },
    del(node) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/zc_type_delete", {
          id: node.data.id,
          type: this.type,
        }).then(res => {
          if(res.data.code==200||res.data.affectedRows){
            this.$message({
              type: 'success',
              message: '删除成功'
            });
            this.getData();
          }else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }

        })
      }).catch(() => {
      });
    },
    handleCascaderChange(e) {
      console.log(e)
    },
    save() {
      let _this = this
      if(!this.EditItem.cname){
        this.$message.error('请填写分类名称~')
        return
      }
      if(!this.EditItem.ename){
        this.$message.error('请填写英文编码~')
        return
      }
      let data = JSON.parse(JSON.stringify(this.EditItem))
      data.remark = ""
      data.zdjson = JSON.stringify(_this.cus_list)
      data.type = this.type
      console.log(data)
      this.$http.post("/api/zc_type_save", data).then(res => {
        _this.dialogVisible = false
        _this.$message({
          type: 'success',
          message: '保存成功'
        });
        _this.getData()
        if (_this.parentPage == "sxlist") {
          _this.$$parent(_this, 'refreshType')
        }
      })
    },
    addCus() {
      this.cus_list.push({name: "", type: "string"})
    },
    delCus(item, index) {
      this.cus_list.splice(index, 1)
    },
    //导入
    uploadXls() {
      document.getElementById('addXlsbtn').click()
    },
    uploadAttch(e) {
      let _this = this
      this.$http.post("/api/import_zc_type_xls", {url: e.src,type:'sx'}).then(res => {
        if(res.data.code==200){
          this.$message.success('导入成功');
        }else {
          this.$message.error(res.data.msg);
        }
        if(res.data.code!=200&&res.data&&res.data&&res.data.msg&&res.data.msg.length > 0) {
          this.ErrLog = res.data.msg
          this.dialogErrorVisible = true;
        }
        _this.getData()
      })
    },
  }
}
</script>

<style lang="less" type="text/scss">
.page_zctype {
  .el-divider {
    margin-top: 0;
    margin-bottom: 0px;
  }

  .drawer_box {
    .el-form-item {
      margin-bottom: 0px;
    }
  }
}
</style>
<style lang="less" type="text/scss" scoped="scoped">
.custom-tree-node {
  width: 100%;
}

::v-deep .linetree {
  width: 100%;

  .el-tree > .el-tree-node > .el-tree-node__content:first-child {

    &::before,
    &::after {
      border: none;
    }
  }
  .el-tree .el-tree-node {
    position: relative;
    white-space: normal;
  }
  .el-tree-node .el-tree-node__content {
    height: 30px;
    width: 100%;
    &::before,
    &::after {
      content: "";
      position: absolute;
      right: auto;
    }

    &::before {
      border-left: 1px solid #c0c4cc;
      bottom: 50px;
      height: 100%;
      top: 0;
      width: 1px;
      margin-left: -5px;
      margin-top: -15px;
      z-index: 9;
    }

    &::after {
      border-top: 1px solid #c0c4cc;
      height: 20px;
      top: 14px;
      width: 10px;
      margin-left: -5px;
      z-index: 9;
    }
  }


}
</style>
